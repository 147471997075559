import React, {Component} from 'react'
import { Link } from 'gatsby'

import "../css/main.sass"
import style from "../css/pages-css/error404.module.sass"
import logoPng from '../images/logo.png'

import SquareButton from '../components/SquareButton'

class NotFoundPage extends Component{

  messages = ["Nothing cool here.", "Such emptiness...", "We are all looking for something, but it's not here.", "Whoever got you here, lied to you."]

  state = {
    message: "error"
  }

  constructor(props){
    super(props)
    this.state.message = this.getRandomMessage()
  }

  render(){
    return(
      <main className={"standard-page"}>
        <Link to="/" className="non-text"><img className={style.logo} src={logoPng} alt=""/></Link>
        <div className={style.main}>
          <h1 className="fw-black huge">404</h1>
          <h4 className="fw-medium">{this.getRandomMessage()}</h4>
          <div className={style.home}><SquareButton text="Home Page"></SquareButton></div>
        </div>
      </main>
    )
  }

  getRandomMessage(){
    let rnd = Math.floor(Math.random() * this.messages.length);
    return this.messages[rnd];
  }
}
  


export default NotFoundPage
