import React, {Component} from "react"
import { Link } from 'gatsby'

import style from '../css/components-css/squarebutton.module.sass'

class SquareButton extends Component{

    state = {
        text: "button",
        link: "/"
    }

    constructor(props){
        super(props)

        if(this.props.text != undefined){
            this.state.text = this.props.text
        }
        if(this.props.link != undefined){
            this.state.link = this.props.link
        }
    }

    render(){
        return(
            <Link className={style.button} to={this.state.link}>
                <h4 className={style.text + " fw-medium"} >{this.state.text}</h4>
            </Link>
        )
    }
}

export default SquareButton
